<template>
	<div v-if="show" class="drawer">
		<el-drawer destroy-on-close :visible.sync="show" size="800px" :show-close="false">
			<div class="drawer-show">
				<div class="drawer-show-header">
					<div class="drawer-show-header-body">
						<h2 class="fl drawer-show-add-name">
							<a href="javascript:void(0);" @click="copyName" class="copy-a"
								:data-clipboard-text="name">{{ name }}</a>
						</h2>
						<div class="fr">
							<el-button class="drawer-show-add-close" type="text" icon="el-icon-close"
								@click="show = false" />
						</div>
					</div>
				</div>
				<div class="drawer-show-body">
					<div style="margin-top: 20px">
						<el-tabs v-model="activeName" type="border-card">
							<el-tab-pane label="基本信息" name="基本信息">
								<div class="drawer-item">
									<p class="drawer-item-name">公司</p>
									{{ data.companyName }}
								</div>
								<div class="drawer-item">
									<p class="drawer-item-name">发货单位</p>
									{{ data.supplierName }}
								</div>
								<div class="drawer-item">
									<p class="drawer-item-name">收货单位</p>
									{{ data.customerName }}
								</div>
								<div class="drawer-item">
									<p class="drawer-item-name">产品</p>
									{{ data.productName }}
								</div>
								<div class="drawer-item">
									<p class="drawer-item-name">仓库</p>
									{{ data.stockName }}
								</div>
								<div class="drawer-item">
									<p class="drawer-item-name">货位</p>
									{{ data.locationName }}
								</div>
								<div class="drawer-item">
									<p class="drawer-item-name">订单时间</p>
									{{ data.orderDate }}
								</div>
								<div class="drawer-item">
									<p class="drawer-item-name">订单数量</p>
									{{ data.orderNum }}
								</div>
								<div class="drawer-item">
									<p class="drawer-item-name">发货量</p>
									{{ data.sendNum }}
								</div>
								<div class="drawer-item">
									<p class="drawer-item-name">收货车数</p>
									{{ data.sendCarNum }}
								</div>
                                <div class="drawer-item">
                                    <p class="drawer-item-name">结算方式</p>
                                    {{ data.settleMethod }}
                                </div>
                                <div class="drawer-item">
                                    <p class="drawer-item-name">路耗方式</p>
                                    {{ data.roadMethod }}
                                </div>
                                <div class="drawer-item" v-if="data.roadMethod === '路耗量'">
                                    <p class="drawer-item-name">路耗量(吨)</p>
                                    {{ data.roadNum }}
                                </div>
                                <div class="drawer-item" v-if="data.roadMethod === '路耗率'">
                                    <p class="drawer-item-name">路耗率(%)</p>
                                    {{ data.roadRate }}
                                </div>
								<div class="drawer-item">
									<p class="drawer-item-name">货物单价</p>
									{{ data.goodPrice }}
								</div>
								<div class="drawer-item">
									<p class="drawer-item-name">运费单价</p>
									{{ data.feightPrice }}
								</div>
								<div class="drawer-item">
									<p class="drawer-item-name">备注</p>
									{{ data.note }}
								</div>
								<vxe-table align="center" :data="data.boundList" border highlight-hover-row auto-resize
									size="small" ref="bdTable" resizable row-id="id"
									show-header-overflow show-overflow :export-config="{}" :print-config="{}">
									<template #empty>
										<el-empty></el-empty>
									</template>
									<vxe-table-column field="code" title="编号"></vxe-table-column>
									<vxe-table-column field="carNum" title="车牌号"></vxe-table-column>
									<vxe-table-column field="primaryWeight" title="原发重量"></vxe-table-column>
									<vxe-table-column field="netWeight" title="净重"></vxe-table-column>
									<vxe-table-column field="assignTime" title="派车时间"></vxe-table-column>
								</vxe-table>
							</el-tab-pane>
						</el-tabs>
					</div>
				</div>
			</div>
		</el-drawer>
	</div>
</template>
<script>
export default {
	data() {
		return {
			show: false,
			data: {},
			activeName: '基本信息',
			name: '',
		}
	},
	methods: {
		display(data) {
			this.name = data.name
			this.data = data.data
			this.show = true
			this.activeName = '基本信息'
		},
		copyName() {
			let clipboard = new this.Clipboard('.copy-a')
			clipboard.on('success', () => {
				this.$message.success('复制成功')
				clipboard.destroy()
			})
			clipboard.on('error', () => {
				this.$message.error('该浏览器不支持自动复制')
				clipboard.destroy()
			})
		}
	}
}
</script>

