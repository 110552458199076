<template>
    <div class="base-container">
        <vxe-toolbar perfect size="mini" style="height: 90px;">
            <template v-slot:buttons>
                <div class="cf table-header-padding" style="height: 100%;">
                    <div class="fl" style="width:85%">
                        <el-button type="primary" @click="getdetail()" size="mini" class="el-icon-plus"
                                   style="margin-right: 10px">
                            新增采购订单
                        </el-button>
                        <el-radio-group @change="searchInit()" size="mini" v-model="isClose" style="margin-right: 10px">
                            <el-radio-button label="0">打开</el-radio-button>
                            <el-radio-button label="1">关闭</el-radio-button>
                        </el-radio-group>
                        <el-input v-model="search.code" clearable placeholder="输入订单编号" size="mini"
                                  style="width: 120px; margin: 0 10px 5px 0" @change="searchInit()">
                        </el-input>
                        <el-select v-model="search.companyId" clearable filterable placeholder="选择公司" size="mini"
                                   style="width: 120px; margin: 0 10px 5px 0" @change="searchInit()">
                            <el-option v-for="(item, index) in company" :key="index" :label="item.name"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                        <el-select v-model="search.supplierId" clearable filterable placeholder="选择发货单位" size="mini"
                                   style="width: 120px; margin: 0 10px 5px 0" @change="searchInit()">
                            <el-option v-for="(item, index) in supplier" :key="index" :label="item.name"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                        <el-select v-model="search.customerId" clearable filterable placeholder="选择收货单位" size="mini"
                                   style="width: 120px; margin: 0 10px 5px 0" @change="searchInit()">
                            <el-option v-for="(item, index) in company" :key="index" :label="item.name"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                        <el-select v-model="search.productId" clearable filterable placeholder="选择产品" size="mini"
                                   style="width: 120px; margin: 0 10px 5px 0" @change="searchInit()">
                            <el-option v-for="(item, index) in product" :key="index" :label="item.name"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                        <el-select v-model="search.stockId" clearable filterable placeholder="选择仓库" size="mini"
                                   style="width: 120px; margin: 0 10px 5px 0" @change="searchInit()">
                            <el-option v-for="(item, index) in spaceName" :key="index" :label="item.spaceName"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                        <el-date-picker @change="dateValue(search.value)" v-model="search.value" type="datetimerange"
                                        size="mini"
                                        value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始时间"
                                        end-placeholder="结束时间"
                                        :default-time="['00:00:00', '23:59:59']" placeholder="选择日期时间"
                                        class="el-daterange-picker-radius"
                                        style="width: 280px">
                        </el-date-picker>
                    </div>
                    <div class="fr">
                        <el-tooltip class="item" effect="dark" content="查询" placement="top">
                            <el-button size="mini" @click="searchInit()" circle class="el-icon-search">
                            </el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="清空" placement="top">
                            <el-button size="mini" @click="search={}; searchInit()" circle class="el-icon-delete">
                            </el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="导出" placement="top">
                            <el-button @click="exportDataEvent()" size="mini" class="el-icon-download"
                                       circle></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="打印" placement="top">
                            <el-button @click="printDataEvent()" size="mini" circle class="el-icon-printer">
                            </el-button>
                        </el-tooltip>
                    </div>
                </div>
            </template>
        </vxe-toolbar>
        <vxe-table :align="allAlign" :data="data" :height="tableHeight" border :export-config="{}" :print-config="{}"
                   highlight-hover-row auto-resize size="small" ref="purchaseOrder" resizable row-id="id"
                   v-loading="loading"
                   element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" show-header-overflow
                   show-overflow
                   @checkbox-change="changeBoxChange" @checkbox-all="changeBoxChange">
            <template #empty>
                <el-empty></el-empty>
            </template>
            <vxe-table-column type="checkbox" width="45" fixed="left" align="center"></vxe-table-column>
            <vxe-table-column field="code" min-width="180" title="订单编号"></vxe-table-column>
            <vxe-table-column field="companyName" min-width="150" title="公司"></vxe-table-column>
            <vxe-table-column field="supplierName" min-width="150" title="发货单位"></vxe-table-column>
            <vxe-table-column field="customerName" min-width="150" title="收货单位"></vxe-table-column>
            <vxe-table-column field="productName" min-width="150" title="产品"></vxe-table-column>
            <vxe-table-column field="stockName" min-width="150" title="仓库"></vxe-table-column>
            <vxe-table-column field="locationName" min-width="150" title="货位"></vxe-table-column>
            <vxe-table-column field="orderDate" min-width="200" title="订单时间"></vxe-table-column>
            <vxe-table-column field="orderNum" min-width="100" title="订单数量"></vxe-table-column>
            <vxe-table-column field="sendNum" min-width="100" title="收货量"></vxe-table-column>
            <vxe-table-column field="goodPrice" min-width="100" title="货物单价"></vxe-table-column>
            <vxe-table-column field="feightPrice" min-width="100" title="运费单价"></vxe-table-column>
            <vxe-table-column field="sendCarNum" min-width="100" title="收货车数"></vxe-table-column>
            <vxe-table-column field="note" min-width="150" title="备注"></vxe-table-column>
            <vxe-table-column fixed="right" align="center" width="180" title="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="mini" @click="getCheck(scope.row)">查看</el-button>
                    <el-button type="text" size="mini" @click="createBd(scope.row)">派车</el-button>
                    <el-button type="text" size="mini" @click="getCar(scope.row)">关联车辆</el-button>
                    <el-button type="text" size="mini" @click="getIsClose(scope.row, 'close')"
                               v-if="isClose == '0'">
                        关闭
                    </el-button>
                    <el-button type="text" size="mini" @click="getIsClose(scope.row, 'open')"
                               v-if="isClose == '1'">打开
                    </el-button>
                </template>
            </vxe-table-column>
        </vxe-table>
        <vxe-pager perfect size="small" align="left" :loading="loading" :current-page="page.currentPage"
                   :page-size="page.pageSize" :total="page.totalResult" class="ext-pager" selectionChange
                   :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']"
                   :page-sizes="[10, 20, 30, 40, 50, 100, 200, 300, 400, 500]" @page-change="handlePageChange">
            <template v-slot:left>
                <div class="fl footer-check">
                    <p>
                        已选
                        <b style="font-size: 10px">{{ checkLength }}</b> 条
                    </p>
                </div>
            </template>
        </vxe-pager>
        <check ref="check"/>
        <detail ref="detail" @init="init"/>
        <create-bd ref="createBd"/>
        <car-list ref="carList"/>
    </div>
</template>

<script>
import check from './components/check'
import detail from './components/detail'
import createBd from './components/createBd.vue'
import carList from './components/carList.vue'
import {mapGetters} from 'vuex'

export default {
    name: 'purchase',
    data() {
        return {
            data: [],
            tableHeight: window.innerHeight - 230 + 'px',
            allAlign: 'center',
            loading: false,
            checkBox: [],
            checkLength: 0,
            page: {
                pageSize: 20,
                currentPage: 1,
                totalResult: 0,
            },
            isClose: '0',
            search: {},
            isNull: ['', null, undefined],
        }
    },
    computed: {
        ...mapGetters(['product', 'customer', 'company', 'supplier', 'spaceName']),
    },
    created() {
        this.init()
        this.$store.dispatch('getProduct')
        this.$store.dispatch('getCustomer')
        this.$store.dispatch('getCompany')
        this.$store.dispatch('getSupplier')
        this.$store.dispatch('getProductType')
        this.$store.dispatch('getSpaceName')
    },
    components: {check, detail, createBd, carList},
    mounted() {
        //监听元素变化
        window.onresize = () => {
            if (this.$refs.purchaseOrder) {
                this.tableHeight = window.innerHeight - this.$refs.purchaseOrder.$el.offsetTop - 62 + 'px'
            }
        }
    },
    methods: {
        init() {
            this.loading = true
            const params = {
                params: {
                    pageNum: this.page.currentPage,
                    pageSize: this.page.pageSize,
                    isClose: this.isClose,
                },
            }
            if (!this.isNull.includes(this.search.code)) params.params.code = this.search.code
            if (!this.isNull.includes(this.search.productId)) params.params.productId = this.search.productId
            if (!this.isNull.includes(this.search.customerId)) params.params.customerId = this.search.customerId
            if (!this.isNull.includes(this.search.supplierId)) params.params.supplierId = this.search.supplierId
            if (!this.isNull.includes(this.search.companyId)) params.params.companyId = this.search.companyId
            if (!this.isNull.includes(this.search.stockId)) params.params.stockId = this.search.stockId
            if (!['', null, undefined].includes(this.search.value)) {
                params.params.startDate = this.search.startDate
                params.params.endDate = this.search.endDate
            }
            this.$axios
                .get('/order/purchaseOrder/page', params)
                .then((res) => {
                    if (res) {
                        const data = res.data.data
                        this.data = data.list
                        this.page.currentPage = data.pageNum
                        this.page.totalResult = data.total
                        this.checkBox = []
                        this.checkLength = 0
                    }
                    this.loading = false
                })
                .catch(() => {
                    this.loading = false
                })
        },
        exportDataEvent() {
            this.$refs.purchaseOrder.openExport()
        },
        printDataEvent() {
            this.$refs.purchaseOrder.openPrint()
        },
        getCheck(row) {
            this.$axios.get(`/order/purchaseOrder/detail/${row.id}`).then((res) => {
                if (res) {
                    let data = {
                        data: res.data.data,
                        name: res.data.data.code,
                    }
                    this.$refs.check.display(data)
                }
            })
        },
        getdetail() {
            let data = {
                data: {
                    feightPrice: 0,
                    goodPrice: 0,
                    settleMethod: 'Primary',
                    roadMethod: 'Num',
                    roadRate: 0,
                    roadNum: 0,
                },
                name: '新增采购订单',
                product: this.product,
                customer: this.customer,
                company: this.company,
                supplier: this.supplier,
                spaceName: this.spaceName,
            }
            this.$refs.detail.display(data)
        },
        /** 选择条数 */
        changeBoxChange() {
            this.checkBox = this.$refs.purchaseOrder.getCheckboxRecords()
            this.checkLength = this.$refs.purchaseOrder.getCheckboxRecords().length
        },
        /** 条数更改 */
        handlePageChange({currentPage, pageSize}) {
            const _this = this
            _this.page.currentPage = currentPage
            _this.page.pageSize = pageSize
            _this.init()
        },
        searchInit() {
            this.page.currentPage = 1
            this.init()
        },
        getIsClose(row, type) {
            let text = ''
            if (type === 'close') {
                text = '关闭'
            } else {
                text = '打开'
            }
            this.$confirm(`确定${text}该条数据吗？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.$axios.get(`/order/purchaseOrder/${type}/${row.id}`).then((res) => {
                        if (res) {
                            this.init()
                            this.$message.success(`${text}成功`)
                        }
                    })
                })
                .catch(() => {
                })
        },
        dateValue(value) {
            if (value) {
                this.$set(this.search, 'startDate', value[0])
                this.$set(this.search, 'endDate', value[1])
            }
        },
        createBd(row) {
            const data = {data: {orderId: row.id, type: 'Purchase', isInside: true}, name: row.code}
            this.$refs.createBd.display(data)
        },
        getCar(row) {
            const data = { row: row, name: '车辆列表' }
            this.$refs.carList.display(data)
        }
    },
}
</script>

<style scoped></style>
