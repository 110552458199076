<template>
    <div v-if="show" class="drawer">
        <el-drawer destroy-on-close :visible.sync="show" title size="600px" :show-close="false">
            <div class="show-drawer">
                <div class="drawer-show-header">
                    <div class="drawer-show-header-body">
                        <h2 class="fl drawer-show-add-name">
                            <a href="javascript:void(0);" @click="copyName" class="copy-a" :data-clipboard-text="name">{{
                                    name
                                }}</a>
                        </h2>
                        <div class="fr">
                            <el-upload action :http-request="upLoad" style="display:inline-block; margin-right:10px;"
                                       :before-upload="beforeUp" accept=".xlsx,.xls" :show-file-list="false">
                                <el-button type="primary" size="small" v-loading="uploadLoading">批量派车</el-button>
                            </el-upload>
                            <el-button size="small" @click="download()">下载批量派车模板</el-button>
                            <el-button size="small" @click="show=false">取 消</el-button>
                            <el-button size="small" type="primary" @click="save" :loading="loading">确定</el-button>
                        </div>
                    </div>
                </div>
                <div class="drawer-show-body">
                    <el-form ref="form" size="small" :model="data" label-width="auto" label-position="right"
                             :rules="rules">
                        <el-form-item prop="carCode" label="车牌号">
                            <el-autocomplete v-model="data.carCode" :fetch-suggestions="queryCarCodeSearch"
                                             placeholder="输入车牌号"
                                             style="width: 100%"></el-autocomplete>
                        </el-form-item>
                        <el-form-item prop="primaryWeight" label="原发重量">
                            <el-input v-model="data.primaryWeight" placeholder="输入原发重量" type="number"></el-input>
                        </el-form-item>
                        <el-form-item prop="note" label="备注">
                            <el-input v-model="data.note" placeholder="输入备注" type="textarea"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
export default {
    data() {
        const validateCarCode = (rule, value, callback) => {
            if (value) {
                if (this.$validate.validateCarNum(value)) {
                    callback()
                    this.isCode = true
                } else {
                    callback(new Error('请输入正确的车牌号'))
                    this.isCode = false
                }
            } else {
                callback(new Error('请输入车牌号'))
                this.isCode = false
            }
        }
        return {
            show:false,
            name:'',
            data:{},
            rules:{
                carCode:[{required:true, trigger:'change', validator:validateCarCode}],
                primaryWeight:[{required:true, trigger:'blur', message:'输入原发重量'}],
            },
            loading:false,
            carCodeList:[],
            uploadLoading:false,
            file:null
        }
    },
    mounted() {
        this.carCodeList = this.getCarCodeList()
    },
    methods:{
        /** 打开界面方法 */
        display(data) {
            this.show = true
            this.data = this.$utils.clone(data.data, true)
            this.name = data.name
        },
        copyName() {
            let clipboard = new this.Clipboard('.copy-a')
            clipboard.on('success', () => {
                this.$message.success('复制成功')
                clipboard.destroy()
            })
            clipboard.on('error', () => {
                this.$message.error('该浏览器不支持自动复制')
                clipboard.destroy()
            })
        },
        /** 车牌号列表赋值历史记录方法 */
        getCarCodeList() {
            const userId = JSON.parse(localStorage.getItem('userInfo')).userId
            let carCode = []
            if (localStorage.getItem('carCode')) {
                carCode = JSON.parse(localStorage.getItem('carCode'))
            }
            let carCodeList = []
            if (carCode.length !== 0) {
                carCode.forEach((car) => {
                    if (car.userId === userId) {
                        car.carCodeList.forEach((item) => {
                            carCodeList.push({value:item})
                        })
                    }
                })
            }
            return carCodeList
        },

        queryCarCodeSearch(queryString, cb) {
            var restaurants = this.carCodeList
            var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
            // 调用 callback 返回建议列表的数据
            cb(results)
        },
        createFilter(queryString) {
            return (restaurant) => {
                return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
            }
        },
        /** 保存方法 */
        save() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.loading = true
                    this.$axios
                        .post('/order/bound/assignCar', this.data)
                        .then(() => {
                            this.$message.success('派车成功')
                            this.show = false
                            this.$emit('init')
                            this.getCarCode()
                            this.loading = false
                        })
                        .catch((err) => {
                            console.log('派车失败', err)
                            this.loading = false
                        })
                } else {
                    return false
                }
            })
        },

        /** 车牌号历史记录方法 */
        getCarCode() {
            let carCode = []
            if (localStorage.getItem('carCode')) {
                carCode = JSON.parse(localStorage.getItem('carCode'))
            }
            let userId = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).userId : ''
            let userName = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).name : ''
            let isUserId = false
            if (carCode.length === 0) {
                carCode = [{userId:userId, userName:userName, carCodeList:[this.data.carCode]}]
            } else if (carCode.length !== 0) {
                carCode.forEach((item) => {
                    if (item.userId === userId) {
                        isUserId = true
                        item.carCodeList.forEach((car, index) => {
                            if (car === this.data.carCode) {
                                item.carCodeList.splice(index, 1)
                            }
                        })
                        item.carCodeList.unshift(this.data.carCode)
                    }
                })
                if (!isUserId) {
                    carCode.push({userId:userId, userName:userName, carCodeList:[this.data.carCode]})
                }
            }
            localStorage.setItem('carCode', JSON.stringify(carCode))
        },
        download() {
            let a = document.createElement('a')
            a.href = `/api/order/bound/download`
            a.style.display = 'none'
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        },
        beforeUp(file) {
            const _this = this
            let suffix = this.getFileType(file.name) //获取文件后缀名
            let suffixArray = ['xlsx', 'xls'] //限制的文件类型，根据情况自己定义
            if (suffixArray.indexOf(suffix) === -1) {
                _this.$message({
                    message:'文件格式错误,请上传xls或者xlsx文件',
                    type:'error',
                    duration:2000,
                })
                return suffixArray
            } else {
                _this.file = new FormData()
                _this.file.append('file', file)
            }
        },
        getFileType(name) {
            let startIndex = name.lastIndexOf('.')
            if (startIndex !== -1) {
                return name.slice(startIndex + 1).toLowerCase()
            } else {
                return ''
            }
        },
        upLoad() {
            const _this = this
            _this.uploadLoading = true
            _this.$axios.post(`/order/bound/upload?orderId=${this.data.orderId}&type=${this.data.type}`, _this.file).then((res) => {
                if (res) {
                    _this.show = false
                    _this.$message.success('派车成功')
                    _this.$emit('init')
                }
                _this.uploadLoading = false
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.dialog-center {
    ::v-deep .el-dialog__header {
        padding: 0;
    }

    ::v-deep .el-dialog__headerbtn {
        top: 13px;
        font-weight: bold;
        font-size: 20px;
    }
}
</style>