<template>
    <div v-if="show">
        <vxe-modal v-model="show" :title="name" width="700" min-width="700" height="80%" min-height="80%" showFooter
                   esc-closable mask-closable resize>
            <template v-slot>
                <el-form ref="form" size="small" :model="data" label-width="auto" label-position="right" :rules="rules">
                    <el-form-item prop="carCodes" label="已选车辆">
                        <div class="carCodes">
                            <el-tag :key="tag" v-for="tag in data.carCodes" size="mini" class="carCode" type="success" closable :disable-transitions="false" @close="handleClose(tag)">
                                {{tag}}
                            </el-tag>
                            <el-button @click="closeAll" size="mini" type="primary" v-if="data.carCodes.length > 0">清除全部</el-button>
                        </div>
                    </el-form-item>
                </el-form>
                <vxe-toolbar perfect size="mini">
                    <template v-slot:buttons>
                        <div class="cf table-header-padding">
                            <div class="fl">
                                <el-input v-model="search.code" clearable placeholder="输入车牌号" size="mini"
                                          style="width: 120px; margin-right: 10px" @change="searchInit()">
                                </el-input>
                                <el-input v-model="search.remark" clearable placeholder="输入备注" size="mini"
                                          style="width: 120px; margin-right: 10px" @change="searchInit()">
                                </el-input>
                            </div>
                        </div>
                    </template>
                </vxe-toolbar>
                <vxe-table align="center" :data="carList" height="50%" border
                           highlight-hover-row auto-resize size="mini" ref="vehicle" resizable row-id="id"
                           @checkbox-change="changeBoxChange" @checkbox-all="changeBoxChange">
                    <template #empty>
                        <el-empty></el-empty>
                    </template>
                    <vxe-table-column type="checkbox" width="45" fixed="left" align="center"></vxe-table-column>
                    <vxe-table-column field="code" min-width="120" title="车牌号"></vxe-table-column>
                    <vxe-table-column field="color" min-width="150" title="车辆颜色"></vxe-table-column>
                    <vxe-table-column field="loadWeight" min-width="150" title="车辆载重(吨)"></vxe-table-column>
                    <vxe-table-column field="remark" min-width="150" title="备注"></vxe-table-column>
                </vxe-table>
                <vxe-pager perfect size="small" align="left" :loading="loading" :current-page="page.currentPage"
                           :page-size="page.pageSize" :total="page.totalResult" class="ext-pager" selectionChange
                           :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']"
                           :page-sizes="[10, 20, 30, 40, 50, 100, 200, 300, 400, 500]" @page-change="handlePageChange">
                    <template v-slot:left>
                        <div class="fl footer-check">
                            <p>
                                已选
                                <b style="font-size: 10px">{{ checkLength }}</b> 条
                            </p>
                        </div>
                    </template>
                </vxe-pager>
            </template>
            <template v-slot:footer>
                <div style="text-align: center">
                    <vxe-button status="primary" style="width: 100%" @click="save" v-loading="loading">确定</vxe-button>
                </div>
            </template>
        </vxe-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show:false,
            name:'',
            data:{},
            rules:{
                carCodes:[{required:true, trigger:'change', message:'请选择关联车辆'}]
            },
            loading:false,
            carList:[],
            page: {
                pageSize: 20,
                currentPage: 1,
                totalResult: 0
            },
            search: {},
            isNull:['', null, undefined],
            checkBox: [],
            checkLength: 0
        }
    },
    methods:{
        /** 打开界面方法 */
        display(data) {
            this.show = true
            this.data = this.$utils.clone(data.data, true)
            this.name = data.name
            this.checkBox = []
            this.checkLength = 0
            this.page = {
                pageSize: 20,
                currentPage: 1,
                totalResult: 0
            }
            this.init()
            this.$nextTick(() => {
                this.$refs.form.clearValidate()
            })
        },
        init() {
            const params = {
                params:{
                    pageNum:this.page.currentPage,
                    pageSize:this.page.pageSize
                },
            }
            if (!this.isNull.includes(this.search.code)) params.params.code = this.search.code
            if (!this.isNull.includes(this.search.remark)) params.params.remark = this.search.remark
            this.$axios
                .get('/order/car/tenantPage', params)
                .then((res) => {
                    if (res) {
                        const data = res.data.data
                        this.carList = data.list
                        this.page.currentPage = data.pageNum
                        this.page.totalResult = data.total
                        this.checkBox = []
                        this.checkLength = 0
                    }
                })
                .catch(() => {
                })
        },
        /** 保存方法 */
        save() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.loading = true
                    const params = this.data
                    this.$axios
                        .post('/order/ordercar/add', params)
                        .then((res) => {
                            if (res) {
                                this.$message.success('关联成功')
                                this.show = false
                                this.$emit('init')
                            }
                            this.loading = false
                        })
                        .catch((err) => {
                            console.log(err)
                            this.loading = false
                        })
                } else {
                    return false
                }
            })
        },
        handlePageChange({
                             currentPage,
                             pageSize
                         }) {
            this.page.currentPage = currentPage
            this.page.pageSize = pageSize
            this.init()
        },
        searchInit() {
            this.page.currentPage = 1
            this.init()
        },
        changeBoxChange() {
            this.checkBox = this.$refs.vehicle.getCheckboxRecords()
            this.checkLength = this.$refs.vehicle.getCheckboxRecords().length
            this.checkBox.forEach(item => {
                if (!this.data.carCodes.includes(item.code)) {
                    this.data.carCodes.push(item.code)
                }
            })
        },
        handleClose(tag) {
            this.data.carCodes.splice(this.data.carCodes.indexOf(tag), 1);
            this.$refs.vehicle.clearCheckboxRow()
            this.checkBox = []
            this.checkLength = 0
        },
        closeAll() {
            this.data.carCodes = []
            this.$refs.vehicle.clearCheckboxRow()
            this.checkBox = []
            this.checkLength = 0
        }
    }
}
</script>

<style scoped>
.carCodes {
    border: 1px solid #f8f8f9;
    border-radius: 5px;
    box-sizing: border-box;
    width: 100%;
    padding: 5px 10px;
    min-height: 44px;
    margin-bottom: 10px;
}
.carCode {
    margin: 0 5px 5px 0;
}
</style>